<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <router-link :to="'/iot/app/appvesion/addappversion/'+0">
          <el-button type="primary" size="small" >新建</el-button>
        </router-link>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="appid">
        <template v-slot="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="app名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="app描述">
        <template v-slot="scope">
          <span>{{ scope.row.descr }}</span>
        </template>
      </el-table-column>
      <el-table-column label="密钥">
        <template v-slot="scope">
          <el-button type="info" size="mini" @click="GetSecret(scope.row)">密钥</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="公钥">
        <template v-slot="scope">
          <span>{{ scope.row.public_key }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="允许分享人数">
        <template v-slot="scope">
          <span>{{ scope.row.share_count }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="目录结构展示方式">
        <template v-slot="scope">
          <span>{{ scope.row.show_type==1?'按功能架构方式展示':'自定义展示' }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="350px">
        <template v-slot="scope">
          <router-link style="margin-right:10px" :to="'/iot/app/appvesion/addappversion/'+scope.row.code">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteAppVersion(scope.row.code)">删除</el-button>
          <router-link style="margin-left:10px" :to="'/iot/app/appshow/'+scope.row.code">
            <el-button type="info" size="small">展示类目</el-button>
          </router-link>
          <router-link style="margin-left:10px" :to="'/iot/app/brandshow/'+scope.row.code">
            <el-button type="info" size="small">展示品牌</el-button>
          </router-link>
          <!-- <el-button type="info" size="small" @click="solid(scope.row)">固件包</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="APP_SECRET" v-model="dialogFormVisible" width="30%" center>
      <el-form :model="form" label-width="80px">
        <el-form-item label="app密钥">
          <el-input v-model="appsecret" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="app公钥">
          <el-button size="mini" type="info" @click="DownPublic(publicsecret)">下载公钥</el-button>
          <!-- <el-input v-model="publicsecret" autocomplete="off"></el-input> -->
        </el-form-item>
        <!-- <el-form-item label="app私钥">
          <el-input v-model="privatesecret" autocomplete="off"></el-input>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="copy(appsecret)">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../../axios/secret'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: ''
      },
      form: {},
      list: [],
      total: 0,
      dialogFormVisible: false,
      appsecret: '',
      publicsecret: '',
      privatesecret: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.ClientList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteAppVersion(item) {
      this.$confirm('是否删除该app版本?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ClientDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    GetSecret (item) {
      api.clientapp({
        code: item.code
      }).then(res => {
        if (res.data.code == 200) {
          this.appsecret = res.data.data.app_secret
          this.publicsecret = res.data.data.public_key
          this.privatesecret = res.data.data.private_key
          this.dialogFormVisible = true
          return
        }
        this.$message.error('获取密钥失败' + res.data.msg)
      })
    },
    copy (appsecret) {
      // this.dialogFormVisible = false
      // var inputTest = document.createElement('input');
      // inputTest.value = appsecret;
      // document.body.appendChild(inputTest);
      // inputTest.select();
      // document.execCommand("Copy");
      // inputTest.className = 'oInput';
      // inputTest.style.display = 'none';
      // this.$message.success('复制成功')
      this.dialogFormVisible = false
    },
    DownPublic (item) {
      // 下载文件方法
      console.log(item,'公钥')
			var eleLink = document.createElement('a');
			eleLink.download = "公钥.txt"
			eleLink.style.display = 'none';
			// 字符内容转变成blob地址
			var blob = new Blob([item]);
			eleLink.href = URL.createObjectURL(blob);
			// 触发点击
			document.body.appendChild(eleLink);
			eleLink.click();
			// 然后移除
			document.body.removeChild(eleLink);
    },
    solid (){
      this.$router.push('/iot/app/appvesion/solid')
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
